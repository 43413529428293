import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Dict } from 'mixpanel-browser';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { State } from '@app/app.reducer';
import {
  fluAndCovidAppointmentTypeIds,
  MENTAL_HEALTH_APPOINTMENT_BOOKED,
  MENTAL_HEALTH_ONLY_TOPICS_APPOINTMENT_BOOKED,
  mentalHealthAppointmentTypes,
  NOT_COVID_OR_FLU_APPOINTMENT_BOOKED,
  onlyMentalHealthTopicAppointmentTypesFromBhxExperiment,
} from '@app/appointment/appointment-type-experiment-data';
import { ANALYTICS_TIMESTAMP_FORMAT } from '@app/core/analytics.service';
import {
  AppointmentAnalyticsBaseService,
  AppointmentAnalyticsProperty,
} from '@app/core/appointment-analytics-base.service';
import { FeatureFlagSelectors } from '@app/core/feature-flags/feature-flag.selectors';
import { FeatureFlags } from '@app/core/feature-flags/feature-flags';
import { LaunchDarklyService } from '@app/core/feature-flags/launchdarkly.service';
import {
  APPOINTMENT_CANCEL_CLICKED,
  APPOINTMENT_CANCELLED,
  APPOINTMENT_RESCHEDULE_CLICKED,
  EVENT_BOOK_VISIT_CLICKED,
  EVENT_RECOMMENDED_SERVICES_CARD_CLICKED,
  EVENT_SCHEDULE_VISIT_CLICKED,
  EXPLORE_OTHER_APPOINTMENTS_CLICKED,
  FLOW_APPOINTMENT_BOOKING,
  MODULE_HOME_PAGE,
  MODULE_MENTAL_HEALTH_TOPIC_ROUTING_PAGE,
  MP_EVENT_PAGE_VIEWED,
} from '@app/core/mixpanel.constants';
import { MixpanelService } from '@app/core/mixpanel.service';
import { AppointmentBookingSource } from '@app/shared/appointment-booking-source';
import { formatDate } from '@app/shared/date-format.pipe';

import { SurveyQuestion } from '../survey/survey-models';
import { ServiceArea } from './../shared/service-area';
import { AppointmentAnalyticsTypeInfoGraphQLService } from './appointment-analytics-type-info-graphql.service';
import { AppointmentBookingState } from './appointment-booking-state-service/appointment-booking-state';
import { AppointmentSearchState } from './appointment-search-service/appointment-search-state';
import { AppointmentType, MENTAL_HEALTH_VISIT_APPT_TYPE_ID } from './appointment-type';
import { AppointmentInventory } from './provider-inventories';
import { BiosInBookingExperiment } from './search-results/provider-detail/provider-detail.component';

export enum TrackableFilterType {
  DATE = 'date',
  LOCATION = 'location',
}

@Injectable({
  providedIn: 'root',
})
export class AppointmentAnalyticsService extends AppointmentAnalyticsBaseService {
  protected bookingState: AppointmentBookingState;
  protected searchState: AppointmentSearchState;
  private bookingStartedParams?: { module: AppointmentAnalyticsProperty; flow: AppointmentAnalyticsProperty };

  constructor(
    private featureFlagSelectors: FeatureFlagSelectors,
    private appointmentAnalyticsTypeInfoGraphQLService: AppointmentAnalyticsTypeInfoGraphQLService,
    mixpanel: MixpanelService,
    store: Store<State>,
    launchDarkly: LaunchDarklyService,
    private route: ActivatedRoute,
  ) {
    super(mixpanel, store, launchDarkly);
  }

  bookingStarted() {
    return this.trackWithBookingAndSearchStates('Appointment Booking Started', this.bookingStartedParams);
  }

  reasonSubmitted() {
    return this.trackWithBookingAndSearchStates('Appointment Reason Submitted');
  }

  bookingPageViewed(pageName: string, properties: object = {}) {
    return this.trackWithBookingAndSearchStates(MP_EVENT_PAGE_VIEWED, {
      flow: AppointmentAnalyticsProperty.BookingFlow,
      module: AppointmentAnalyticsProperty.BookingPageModule,
      submodule: pageName,
      source: this.route.snapshot.queryParams.source,
      ...properties,
    });
  }

  reschedulePageViewed() {
    return this.trackWithBookingAndSearchStates(MP_EVENT_PAGE_VIEWED, {
      flow: AppointmentAnalyticsProperty.RescheduleFlow,
      module: AppointmentAnalyticsProperty.ReasonForReschedulePageModule,
      source: this.route.snapshot.queryParams.source,
    });
  }

  searched() {
    this.trackLaunchDarklyDeepLinkInventoryPageViewed(); // BHX team tracking experiment
    return this.trackWithBookingAndSearchStates('Appointment Inventory Searched', {
      module: AppointmentAnalyticsProperty.InventoryPageModule,
      flow: AppointmentAnalyticsProperty.BookingFlow,
      appointment_type: this.searchState.appointmentType.displayName,
      appointment_type_id: this.searchState.appointmentType.id,
    });
  }

  searchFailed() {
    return this.trackWithBookingAndSearchStates('Appointment Inventory Search Failed');
  }

  appointmentTypeSelected(appointmentType: AppointmentType) {
    return this.trackWithBookingAndSearchStates('Appointment Type Selected', {
      appointment_type_id: appointmentType.id,
      appointment_type: appointmentType.displayName,
      flow: FLOW_APPOINTMENT_BOOKING,
    });
  }

  inventorySelected(inventory: AppointmentInventory, props?: { isRemoteRecommendation?: boolean }) {
    const properties: any = {
      module: AppointmentAnalyticsProperty.AppointmentConfirmationPageModule,
      flow: AppointmentAnalyticsProperty.BookingFlow,
      appointment_inventory_id: inventory.id,
    };

    if (props?.isRemoteRecommendation) {
      properties.submodule = AppointmentAnalyticsProperty.NextAvailableRemoteVisitSubmodule;
    }

    return this.trackWithBookingAndSearchStates('Appointment Selected', properties);
  }

  seeMoreAppointmentsClicked(_inventoryCount: number, _props: any) {
    return;
  }

  filterApplied(filterType: string, filterDetails: object) {
    const properties = {
      filter_type: filterType,
      ...filterDetails,
    };
    return this.trackWithBookingAndSearchStates('Filter Applied', properties);
  }

  paginateNext() {
    return this.trackWithBookingAndSearchStates('Next Button Clicked');
  }

  paginatePrevious() {
    return this.trackWithBookingAndSearchStates('Previous Button Clicked');
  }

  bookAppointmentClicked(inventory: AppointmentInventory) {
    const trackProps = {
      flow: AppointmentAnalyticsProperty.BookingFlow,
      appointment_inventory_id: inventory.id,
      selected_date: formatDate(inventory.start_time, ANALYTICS_TIMESTAMP_FORMAT, inventory.timezone),
      module: AppointmentAnalyticsProperty.InventoryPageModule,
      submodule: AppointmentAnalyticsProperty.SelectedAppointmentBookingModal,
    };
    return this.trackWithBookingAndSearchStates('Book Appointment Clicked', trackProps);
  }

  bookingCancelled() {
    return this.trackWithBookingAndSearchStates('Appointment Booking Cancelled');
  }

  appointmentBooked(appointment_id: number, inventory_id: number, appointment_type_id: number) {
    this.trackLaunchDarklyAppointmentBooked(appointment_type_id);

    return this.trackWithBookingAndSearchStates('Appointment Booked', {
      module: AppointmentAnalyticsProperty.AppointmentConfirmationPageModule,
      flow: AppointmentAnalyticsProperty.BookingFlow,
      appointment_id,
      appointment_type_id,
      appointment_inventory_id: inventory_id,
    });
  }

  jumpAheadClicked() {
    return this.trackWithBookingAndSearchStates('Jump Ahead Clicked', {
      module: AppointmentAnalyticsProperty.InventoryPageModule,
      flow: AppointmentAnalyticsProperty.BookingFlow,
    });
  }

  addToCalendarClicked() {
    return this.trackWithBookingAndSearchStates('Add To Calendar Clicked');
  }

  appointmentSurveySubmitted(surveyName: string) {
    return this.trackWithBookingAndSearchStates('Survey Submitted', {
      flow: AppointmentAnalyticsProperty.BookingFlow,
      module: AppointmentAnalyticsProperty.BookingPageModule,
      submodule: surveyName,
    });
  }

  appointmentSurveyQuestionAnswered(surveyName: string, surveyQuestion: SurveyQuestion) {
    return this.trackWithBookingAndSearchStates('Survey Question Answered', {
      flow: AppointmentAnalyticsProperty.BookingFlow,
      module: AppointmentAnalyticsProperty.BookingPageModule,
      submodule: surveyName,
      survey_question: surveyQuestion.questionText,
      survey_answer: surveyQuestion.patientAnswers.map(answer => answer.answerText).join(', '),
    });
  }

  reasonForCancelSubmitted() {
    return this.trackWithBookingAndSearchStates('Reason for Cancel Submitted', {
      flow: AppointmentAnalyticsProperty.RescheduleFlow,
      module: AppointmentAnalyticsProperty.ReasonForReschedulePageModule,
    });
  }

  rescheduled() {
    return this.trackWithBookingAndSearchStates('Appointment Rescheduled', {
      flow: AppointmentAnalyticsProperty.RescheduleFlow,
      module: AppointmentAnalyticsProperty.AppointmentConfirmationPageModule,
    });
  }

  trackOMNowPageViewed() {
    return this.trackWithDefaultProperties(MP_EVENT_PAGE_VIEWED, {
      flow: AppointmentAnalyticsProperty.OMNowNavigationFlow,
      module: AppointmentAnalyticsProperty.VirtualOnlyLandingPageModule,
    });
  }

  trackJoinVideoVisitClicked() {
    return this.trackWithDefaultProperties('Send Link Clicked', {
      flow: AppointmentAnalyticsProperty.OMNowNavigationFlow,
      module: AppointmentAnalyticsProperty.VirtualOnlyLandingPageModule,
      submodule: AppointmentAnalyticsProperty.GetVirtualCareCardSubmodule,
    });
  }

  trackMessageAProviderClicked() {
    return this.trackWithDefaultProperties('Message A Provider Clicked', {
      flow: AppointmentAnalyticsProperty.OMNowNavigationFlow,
      module: AppointmentAnalyticsProperty.VirtualOnlyLandingPageModule,
      submodule: AppointmentAnalyticsProperty.MessageProviderCardSubmodule,
    });
  }

  trackBookVisitClicked() {
    return this.trackWithDefaultProperties(EVENT_BOOK_VISIT_CLICKED, {
      flow: AppointmentAnalyticsProperty.OMNowNavigationFlow,
      module: AppointmentAnalyticsProperty.VirtualOnlyLandingPageModule,
      submodule: AppointmentAnalyticsProperty.GetCareCardSubmodule,
    });
  }

  trackContinueToBookingClicked(
    { name: service_area_selected }: ServiceArea,
    module: AppointmentAnalyticsProperty | string,
    submodule: AppointmentAnalyticsProperty | string,
  ) {
    return this.trackWithDefaultProperties('Continue To Booking Clicked', {
      service_area_selected,
      module,
      submodule,
      flow: AppointmentAnalyticsProperty.OMNowNavigationFlow,
    });
  }

  trackGetCareLinkClicked({ topicName }: { topicName: string }) {
    this.trackWithBookingAndSearchStates('Get Care Link Clicked', {
      topic_name: topicName,
      flow: AppointmentAnalyticsProperty.BookingFlow,
      module: AppointmentAnalyticsProperty.DeepLinkModule,
    });
  }

  /** BHX Program Ineligibility Events */

  // Sets the properties to pass to `.bookingStarted` to track what module/flow is being referred from
  setAppointmentBookingFromBehavioralHealthProgramIneligibility() {
    this.bookingStartedParams = {
      module: AppointmentAnalyticsProperty.BehavioralHealthProgramIneligiblePageModule,
      flow: AppointmentAnalyticsProperty.BookingFlow,
    };
  }

  behavioralHealthProgramIneligibilityPageViewed() {
    return this.trackWithBookingAndSearchStates(MP_EVENT_PAGE_VIEWED, {
      module: AppointmentAnalyticsProperty.BehavioralHealthProgramIneligiblePageModule,
      flow: AppointmentAnalyticsProperty.BookingFlow,
    });
  }

  behavioralHealthProgramIneligibilityMessageUsClicked() {
    return this.trackWithDefaultProperties('Message Us Clicked', {
      module: AppointmentAnalyticsProperty.BehavioralHealthProgramIneligiblePageModule,
      flow: AppointmentAnalyticsProperty.MemberRequestFlow,
    });
  }
  /** End BHX Program Ineligibility Events */

  /** Mindset Events */
  mindsetEducationPageViewed(source?: string) {
    return this.trackWithBookingAndSearchStates(MP_EVENT_PAGE_VIEWED, {
      module: AppointmentAnalyticsProperty.MindsetEducationPageModule,
      flow: AppointmentAnalyticsProperty.BookingFlow,
      source,
    });
  }

  mindsetEducationAppointmentBookingStarted() {
    return this.trackWithBookingAndSearchStates('Appointment Booking Started', {
      module: AppointmentAnalyticsProperty.MindsetEducationPageModule,
      flow: AppointmentAnalyticsProperty.BookingFlow,
    });
  }
  /** End Mindset Events */

  /** Shift Events */
  shiftEducationPageViewed(options: string[], source?: string) {
    return this.trackWithBookingAndSearchStates(MP_EVENT_PAGE_VIEWED, {
      module: AppointmentAnalyticsProperty.ShiftEducationPageModule,
      flow: AppointmentAnalyticsProperty.BookingFlow,
      source: source,
      option_count: options.length,
      options: options,
    });
  }

  shiftEducationAppointmentBookingStarted(appointmentTypeName: string) {
    return this.trackWithBookingAndSearchStates('Appointment Booking Started', {
      module: AppointmentAnalyticsProperty.ShiftEducationPageModule,
      flow: AppointmentAnalyticsProperty.BookingFlow,
      appointment_type_name: appointmentTypeName,
    });
  }

  shiftSeeAllOptionsClicked(optionCount: number) {
    return this.trackWithBookingAndSearchStates('See Options Clicked', {
      module: AppointmentAnalyticsProperty.ShiftEducationPageModule,
      flow: AppointmentAnalyticsProperty.BookingFlow,
      option_count: optionCount,
    });
  }

  shiftCarouselInteraction(interaction: string) {
    return this.trackWithBookingAndSearchStates('Carousel Clicked', {
      module: AppointmentAnalyticsProperty.ShiftEducationPageModule,
      flow: AppointmentAnalyticsProperty.BookingFlow,
      submodule: interaction,
    });
  }
  /** End Shift Events */

  trackRescheduleStarted(appointmentId: string) {
    super.trackWithDefaultProperties('Appointment Reschedule Started', {
      flow: AppointmentAnalyticsProperty.BookingFlow,
      module: AppointmentAnalyticsProperty.AppointmentsPageModule,
      appointment_id: appointmentId,
    });
  }

  trackAppointmentCancellationStarted(appointmentId: string) {
    combineLatest([
      this.appointmentTypeDisplayName$(appointmentId),
      this.featureFlagSelectors.getFeatureFlag(FeatureFlags.VEX_SWITCH_TO_REMOTE),
    ])
      .pipe(
        switchMap(([appointmentTypeDisplayName, variation]) =>
          this.trackWithDefaultProperties(APPOINTMENT_CANCEL_CLICKED, {
            flow: FLOW_APPOINTMENT_BOOKING,
            module: AppointmentAnalyticsProperty.AppointmentsPageModule,
            appointment_id: appointmentId,
            appointment_type: appointmentTypeDisplayName,
            experiment_name: FeatureFlags.VEX_SWITCH_TO_REMOTE,
            experiment_variation_name: variation ? 'ON VARIANT' : 'ON CONTROL',
          }),
        ),
      )
      .subscribe();
  }

  trackAppointmentCanceled(appointmentId: string) {
    super.trackWithDefaultProperties(APPOINTMENT_CANCELLED, {
      flow: AppointmentAnalyticsProperty.BookingFlow,
      module: AppointmentAnalyticsProperty.AppointmentsPageModule,
      appointment_id: appointmentId,
    });
  }

  trackAppointmentRescheduleStarted(appointmentId: string) {
    combineLatest([
      this.appointmentTypeDisplayName$(appointmentId),
      this.featureFlagSelectors.getFeatureFlag(FeatureFlags.VEX_SWITCH_TO_REMOTE),
    ])
      .pipe(
        switchMap(([appointmentTypeDisplayName, variation]) =>
          this.trackWithDefaultProperties(APPOINTMENT_RESCHEDULE_CLICKED, {
            flow: FLOW_APPOINTMENT_BOOKING,
            module: AppointmentAnalyticsProperty.AppointmentsPageModule,
            appointment_id: appointmentId,
            appointment_type: appointmentTypeDisplayName,
            experiment_name: FeatureFlags.VEX_SWITCH_TO_REMOTE,
            experiment_variation_name: variation ? 'ON VARIANT' : 'ON CONTROL',
          }),
        ),
      )
      .subscribe();
  }

  trackMarkedAsRunningLateToAppointment(appointmentId: string) {
    super.trackWithDefaultProperties('Appointment Marked As Running Late', {
      flow: AppointmentAnalyticsProperty.BookingFlow,
      module: AppointmentAnalyticsProperty.AppointmentsPageModule,
      appointment_id: appointmentId,
    });
  }

  trackWaitlistShown() {
    this.trackWithBookingAndSearchStates('Inventory Waitlist Shown', {
      module: AppointmentAnalyticsProperty.InventoryPageModule,
      flow: AppointmentAnalyticsProperty.BookingFlow,
    });
  }

  trackWaitlistJoined(memberId: number, state: string, b2bCompanyName: string) {
    this.trackWithBookingAndSearchStates('Inventory Waitlist Joined', {
      module: AppointmentAnalyticsProperty.InventoryPageModule,
      flow: AppointmentAnalyticsProperty.BookingFlow,
      address_state: state,
      patient_id: memberId,
      b2b_company_name: b2bCompanyName,
    });
  }

  trackInventorySearchViewed() {
    this.launchDarklyService
      .featureFlag$(FeatureFlags.BHX_PROVIDER_BIOS_IN_BOOKING_EXPERIMENT, BiosInBookingExperiment.Off)
      .pipe(
        map(variationName => {
          const properties: Dict = {
            flow: AppointmentAnalyticsProperty.BookingFlow,
            module: AppointmentAnalyticsProperty.InventoryPageModule,
          };

          if (variationName !== BiosInBookingExperiment.Off) {
            properties.experiment_name = FeatureFlags.BHX_PROVIDER_BIOS_IN_BOOKING_EXPERIMENT;
            properties.experiment_variation_name = variationName;
          }

          return properties;
        }),
        take(1),
      )
      .subscribe(properties => {
        this.trackWithBookingAndSearchStates(MP_EVENT_PAGE_VIEWED, properties);
      });
  }

  trackRecommendedServiceCardClicked(properties: Dict = {}) {
    return this.trackWithDefaultProperties(EVENT_RECOMMENDED_SERVICES_CARD_CLICKED, {
      flow: FLOW_APPOINTMENT_BOOKING,
      submodule: 'Mental Health Card',
      module: MODULE_HOME_PAGE,
      click_type: 'See Programs',
      ...properties,
    });
  }

  trackMentalHealthRoutingPageViewed(topics: string[]) {
    return this.trackWithDefaultProperties(MP_EVENT_PAGE_VIEWED, {
      flow: FLOW_APPOINTMENT_BOOKING,
      module: MODULE_MENTAL_HEALTH_TOPIC_ROUTING_PAGE,
      source: MODULE_HOME_PAGE,
      topics_displayed: topics,
    });
  }

  trackMentalHealthRoutingProgramSelected(cardTitle: string) {
    return this.trackWithDefaultProperties(EVENT_SCHEDULE_VISIT_CLICKED, {
      flow: FLOW_APPOINTMENT_BOOKING,
      module: MODULE_MENTAL_HEALTH_TOPIC_ROUTING_PAGE,
      submodule: cardTitle,
    });
  }

  trackMentalHealthRoutingExploreOtherProgramsSelected() {
    return this.trackWithDefaultProperties(EXPLORE_OTHER_APPOINTMENTS_CLICKED, {
      flow: FLOW_APPOINTMENT_BOOKING,
      module: MODULE_MENTAL_HEALTH_TOPIC_ROUTING_PAGE,
    });
  }

  private trackLaunchDarklyAppointmentBooked(appointmentTypeId: number) {
    this.trackWithLaunchDarkly({
      key: 'Any Appointment Booked',
      data: { isModuleShown: this.searchState?.trackableProperties?.is_widget_shown },
    });
    if (appointmentTypeId === this.searchState?.trackableProperties?.remoteAppointmentTypeId) {
      this.trackWithLaunchDarkly({
        key: 'Remote Visit Appointment Booked',
        data: { isModuleShown: this.searchState?.trackableProperties?.is_widget_shown },
      });
    }
    // BHX Mental Health PCP Experiment Tracking (LD)
    if (this.isMentalHealthPcpExperiment()) {
      this.trackWithLaunchDarkly({ key: 'Patient Booked PCP Mental Health Visit' });
    }

    // Mental Health Topic Routing Feature (LD)
    if (onlyMentalHealthTopicAppointmentTypesFromBhxExperiment.includes(appointmentTypeId)) {
      this.trackWithLaunchDarkly({ key: MENTAL_HEALTH_ONLY_TOPICS_APPOINTMENT_BOOKED });
    }

    // Mental Health Topic Routing Feature (LD)
    if (!fluAndCovidAppointmentTypeIds.includes(appointmentTypeId)) {
      this.trackWithLaunchDarkly({ key: NOT_COVID_OR_FLU_APPOINTMENT_BOOKED });
    }

    // Mental Health Topic Routing Feature (LD)
    if (mentalHealthAppointmentTypes.includes(appointmentTypeId)) {
      this.trackWithLaunchDarkly({ key: MENTAL_HEALTH_APPOINTMENT_BOOKED });
    }
  }

  private trackLaunchDarklyDeepLinkInventoryPageViewed() {
    // BHX Mental Health PCP Experiment Tracking (LD)
    if (this.isMentalHealthPcpExperiment()) {
      this.trackWithLaunchDarkly({ key: 'Member Appointment Inventory Page viewed' });
    }
  }

  private isMentalHealthPcpExperiment() {
    return (
      this.searchState &&
      this.searchState.getBookingSource() === AppointmentBookingSource.GetCareDeepLinkFlow &&
      this.searchState.appointmentType.id.toString() === MENTAL_HEALTH_VISIT_APPT_TYPE_ID
    );
  }

  private appointmentTypeDisplayName$(appointmentId: string): Observable<string> {
    return this.appointmentAnalyticsTypeInfoGraphQLService
      .fetch({ id: appointmentId })
      .pipe(map(result => result.data.appointment.appointmentType.displayName));
  }
}
