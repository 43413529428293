import { ENVIRONMENT_QUAL } from './qual';

export const environment = {
  ...ENVIRONMENT_QUAL,
  apiServer: 'https://boise.app.1life.com',
  myoneServer: 'https://boise.app.1life.com',
  launchDarklyClientId: '604ff4f3a544ec0cd24feed6',
  newRecaptchaSiteKey: '6LefPsspAAAAAE_SgRmE0rvQbuiQZhsppB6XtwaT',
  sentry: {
    enabled: true,
    dsn: 'https://94fb2cb964527591835f763ad4493a4f@o4506221115408384.ingest.us.sentry.io/4506855554154496',
    environment: 'onelife-boise',
  },
  stripePublishableKey:
    'pk_test_51NtCl2AIeR0xiYdKPxfDSyMYUnRQRanxrUPYYuZWO4YwJLC5PGZ2yhvtdMl0fXwfNN6i4ilLtMGlLaFHBr0npq9s00J37s86mW',
};
