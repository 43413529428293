import { Injectable, Injector, Type } from '@angular/core';
import { Observable } from 'rxjs';

import { NodeUrlMappingService } from '@app/registration/graph/node-url-mapping.service';
import { RegistrationNode } from '@app/registration/graph/nodes/registration.node';
import { StartNode } from '@app/registration/graph/nodes/start.node';
import { RegistrationFlowStepCount } from '@app/registration/routing/constants';
import { WindowService } from '@app/utils/window.service';

export interface RegistrationEdge {
  shouldNavigate$(): Observable<boolean>;

  nextNode: Type<RegistrationNode>;
}

export interface GraphNavigationArgs {
  flowVersion?: string;
  claimCode?: string;
  lwaPurchaseRef?: string;
  stepDetails?: RegistrationFlowStepCount;
  membershipInfo?: GraphMembershipInfo;
}

export interface GraphMembershipInfo {
  membershipType?: string | null;
  subscriptionId?: string | null;
  billingCycle?: string | null;
  membershipSubscriptionType?: string | null;
  isMembershipManager?: boolean | null;
  membershipStatus?: string | null;
}

export interface GraphNavigationAnalyticsProperties extends GraphMembershipInfo {
  flowVersion: string | undefined;
}

@Injectable({
  providedIn: 'root',
})
export class GraphNavigationService {
  percentComplete: number | null = null;
  graphNavigationArgs: GraphNavigationArgs = {};
  currentNode: RegistrationNode;

  private currentStep = 0;
  private totalSteps: number | null = null;

  constructor(
    private injector: Injector,
    private nodeUrlMappingService: NodeUrlMappingService,
    private startNode: StartNode,
    private windowService: WindowService,
  ) {}

  navigate(args?: GraphNavigationArgs): void {
    this.graphNavigationArgs = { ...this.graphNavigationArgs, ...args };
    this.verifyCurrentNode();

    if (!this.currentNode) {
      this.currentNode = this.startNode;
      this.calculateSteps(args?.stepDetails);
      this.executeCurrentNode(this.graphNavigationArgs);
      return;
    }

    this.currentNode.nextNode$().subscribe({
      next: nextNode => {
        this.currentNode = this.injector.get<RegistrationNode>(nextNode);
        this.executeCurrentNode(this.graphNavigationArgs);
      },
    });
  }

  updateMembershipStatus(status: string): void {
    if (!this.graphNavigationArgs.membershipInfo) {
      this.graphNavigationArgs.membershipInfo = {};
    }

    this.graphNavigationArgs.membershipInfo.membershipStatus = status;
  }

  get analyticsProperties(): GraphNavigationAnalyticsProperties {
    return {
      flowVersion: this.graphNavigationArgs.flowVersion,
      ...this.graphNavigationArgs.membershipInfo,
    };
  }

  private executeCurrentNode(args: GraphNavigationArgs) {
    if (this.currentNode.isAsync) {
      this.currentNode.execute$(args).subscribe({
        next: () => this.navigate(args),
      });
    } else {
      ++this.currentStep;
      this.updatePercentComplete();
      this.currentNode.execute(args);
    }
  }

  private updatePercentComplete(): void {
    if (this.totalSteps) {
      this.percentComplete = (this.currentStep / this.totalSteps) * 100;
    }
  }

  private calculateSteps(flowStepDetails?: RegistrationFlowStepCount): void {
    if (!flowStepDetails) {
      return;
    }

    this.totalSteps = flowStepDetails.totalSteps;
    this.currentStep = flowStepDetails.startingStep;
  }

  private verifyCurrentNode() {
    if (this.currentNode?.isAsync) {
      return;
    }

    const urlNode = this.nodeUrlMappingService.getNodeForUrl(this.windowService.getLocationPathname());
    if (urlNode) {
      this.currentNode = this.injector.get<RegistrationNode>(urlNode);
    }
  }
}
